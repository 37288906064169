import React from "react";

import { AnimatedElement } from "./AnimatedElement";

import type { ReactNode } from "react";
import type { BoxProps } from "~components/Box";

export interface AnimatedMockUiWrapperProps extends Omit<BoxProps, "as"> {
  children: ReactNode;
}

export function AnimatedMockUiWrapper({
  children,
  ...rest
}: AnimatedMockUiWrapperProps) {
  return (
    <AnimatedElement
      animationLevel="primary"
      display="flex"
      flexDirection="column"
      height="100%"
      rowGap="spacing2"
      width="100%"
      {...rest}
    >
      {children}
    </AnimatedElement>
  );
}
