/* eslint-disable sonarjs/no-duplicate-string */
import React from "react";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimLayerExpenditureChart } from "~animations/__layers__/mock_ui_components/AnimLayerExpenditureChart";
import { AnimLayerExpenditureTabbedList } from "~animations/__layers__/mock_ui_components/AnimLayerExpenditureTabbedList";

import type { AnimLayerExpenditureChartProps } from "~animations/__layers__/mock_ui_components/AnimLayerExpenditureChart";
import type { AnimLayerExpenditureTabbedListProps } from "~animations/__layers__/mock_ui_components/AnimLayerExpenditureTabbedList";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationTotalSpendCategoriesProps extends StoryblokBlok {
  animLayerExpenditureChart: Array<
    StoryblokBlok & AnimLayerExpenditureChartProps
  >;
  animLayerExpenditureTabbedList: Array<
    StoryblokBlok & AnimLayerExpenditureTabbedListProps
  >;
}

export function AnimationTotalSpendCategories({
  animLayerExpenditureTabbedList: animLayerExpenditureTabbedListBlokArray,
  animLayerExpenditureChart: animLayerExpenditureChartBlokArray,
  ...rest
}: AnimationTotalSpendCategoriesProps) {
  const [animLayerExpenditureChart] = animLayerExpenditureChartBlokArray || [];
  const [animLayerExpenditureTabbedList] =
    animLayerExpenditureTabbedListBlokArray || [];

  return (
    <StoryblokEditable {...rest}>
      <Box position="relative" height="100%" width="100%" {...rest}>
        {animLayerExpenditureChart && (
          <AnimLayerExpenditureChart
            position="absolute"
            top="spacing3"
            left="spacing3"
            width="spacing26"
            {...animLayerExpenditureChart}
          />
        )}
        {animLayerExpenditureTabbedList && (
          <AnimLayerExpenditureTabbedList
            width="gridSpan3"
            position="absolute"
            bottom="spacing3"
            right="spacing3"
            {...animLayerExpenditureTabbedList}
          />
        )}
      </Box>
    </StoryblokEditable>
  );
}
