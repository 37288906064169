import { StatusProgress } from "./StatusProgress";
import { StatusTick } from "./StatusTick";

const STATUS_MAP = {
  progress: StatusProgress,
  tick: StatusTick,
} as const;

export type StatusOption = keyof typeof STATUS_MAP | "none";

export { STATUS_MAP };
