import React from "react";

import { Box } from "~components/Box";
import { PhoneMockup } from "~components/PhoneMockup";

import { SvgLogoIRollerDice } from "~animations/__layers__/logos/SvgLogoIRollerDice";
import { AnimLayerIgamingCards } from "~animations/__layers__/mock_ui_components/AnimLayerIgamingCards";
import { AnimLayerIgamingIcons } from "~animations/__layers__/mock_ui_components/AnimLayerIgamingIcons";
import { AnimLayerUiAccountInfoCard } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import { AnimLayerUiBottomNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomNav";

import type { AnimLayerIgamingCardsProps } from "~animations/__layers__/mock_ui_components/AnimLayerIgamingCards";
import type { AnimLayerIgamingIconsProps } from "~animations/__layers__/mock_ui_components/AnimLayerIgamingIcons";
import type { AnimLayerUiAccountInfoCardProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import type { AnimatedHeroIllustration } from "~animations/products_addons/types";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimIndustryIGamingProps
  extends StoryblokBlok,
    AnimatedHeroIllustration {
  animLayerUiAccountInfoCard: Array<
    StoryblokBlok & AnimLayerUiAccountInfoCardProps
  >;
  animLayerIgamingIcons: Array<StoryblokBlok & AnimLayerIgamingIconsProps>;
  animLayerIgamingCards: Array<StoryblokBlok & AnimLayerIgamingCardsProps>;
}

export function AnimIndustryIGaming({
  animLayerIgamingCards: animLayerIgamingCardsBlokArray,
  animLayerIgamingIcons: animLayerIgamingIconsBlokArray,
  animLayerUiAccountInfoCard: animLayerUiAccountInfoCardBlokArray,
}: AnimIndustryIGamingProps) {
  const [animLayerUiAccountInfoCard] =
    animLayerUiAccountInfoCardBlokArray || [];

  const [animLayerIgamingIcons] = animLayerIgamingIconsBlokArray || [];
  const [animLayerIgamingCards] = animLayerIgamingCardsBlokArray || [];

  return (
    <PhoneMockup
      aspectRatio="mobile"
      display="flex"
      flexDirection="column"
      rowGap="spacing2"
    >
      <Box
        paddingY="spacing2"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SvgLogoIRollerDice />
      </Box>

      {animLayerUiAccountInfoCard && (
        <AnimLayerUiAccountInfoCard
          marginX="spacing2"
          {...animLayerUiAccountInfoCard}
        />
      )}

      {animLayerIgamingIcons && (
        <AnimLayerIgamingIcons marginX="spacing2" {...animLayerIgamingIcons} />
      )}

      {animLayerIgamingCards && (
        <AnimLayerIgamingCards marginX="spacing2" {...animLayerIgamingCards} />
      )}

      <AnimLayerUiBottomNav />
    </PhoneMockup>
  );
}
