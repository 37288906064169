import React from "react";

import { AnimLayerAlert } from "~animations/__layers__/mock_ui_components/AnimLayerAlert";

import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationDepositSuccessProps
  extends StoryblokBlok {
  title?: string;
  description?: string;
}

export function AnimationIllustrationSuccessDeposit({
  title,
  description,
  ...rest
}: AnimationIllustrationDepositSuccessProps) {
  return (
    <AnimLayerAlert
      maxWidth="gridSpan3"
      width="100%"
      icon="faCheckCircle"
      title={title}
      description={description}
      {...rest}
    />
  );
}
