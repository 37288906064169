import * as React from "react";

import { STATUS_MAP } from "./statuses";

import type { AnimationPaymentsListItemModel } from "./types";

export function AnimationPaymentsListItemStatus({
  status,
}: Pick<AnimationPaymentsListItemModel, "status">) {
  if (!status || status === "none") {
    return null;
  }

  const Component = STATUS_MAP[status];

  if (!Component) {
    return null;
  }

  return <Component />;
}
