import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";

import { SvgIllustrationPokerCard } from "~animations/__layers__/SvgIllustrationPokerCard";
import { AnimLayerBalanceCardItem } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardItem";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import type { AnimLayerBalanceCardItemProps } from "~animations/__layers__/mock_ui_components/AnimLayerBalanceCardItem";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationIGamingWithdrawalProps extends BoxProps {
  className: string;
  balanceCard: Array<StoryblokBlok & AnimLayerBalanceCardItemProps>;
  title: string;
}

export function AnimationIllustrationIGamingWithdrawal({
  className: userClassName,
  balanceCard: balanceCardBlokArray,
  ...rest
}: AnimationIllustrationIGamingWithdrawalProps) {
  const [balanceCard] = balanceCardBlokArray || [];

  return (
    <AnimatedElement
      animationLevel="primary"
      className={clsx(userClassName, variantBackgroundColor.background_sand)}
      width="gridSpan3"
      borderRadius="md"
      overflow="hidden"
      {...rest}
    >
      <AnimLayerBalanceCardItem {...balanceCard} position="relative">
        <Box
          width="spacing16"
          position="absolute"
          zIndex="-1"
          top="0"
          __right="-2rem"
        >
          <SvgIllustrationPokerCard />
        </Box>
      </AnimLayerBalanceCardItem>
    </AnimatedElement>
  );
}
