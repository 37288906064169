import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimLayerFakeButton } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import { AnimLayerTravelBookingInfo } from "~animations/__layers__/mock_ui_components/AnimLayerTravelBookingInfo";
import { AnimLayerTravelHotelInfo } from "~animations/__layers__/mock_ui_components/AnimLayerTravelHotelInfo";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import type { AnimLayerTravelBookingInfoProps } from "~animations/__layers__/mock_ui_components/AnimLayerTravelBookingInfo";
import type { AnimLayerTravelHotelInfoProps } from "~animations/__layers__/mock_ui_components/AnimLayerTravelHotelInfo";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationTravelRefundProps extends StoryblokBlok {
  animLayerTravelHotelInfo: Array<
    StoryblokBlok & AnimLayerTravelHotelInfoProps
  >;
  animLayerTravelBookingInfo: Array<
    StoryblokBlok & AnimLayerTravelBookingInfoProps
  >;
  ctaText?: string;
}

export function AnimationIllustrationTravelRefund({
  animLayerTravelHotelInfo: animLayerTravelHotelInfoBlokArray,
  animLayerTravelBookingInfo: animLayerTravelBookingInfoBlokArray,
  ctaText,
  ...rest
}: AnimationIllustrationTravelRefundProps) {
  const [animLayerTravelHotelInfo] = animLayerTravelHotelInfoBlokArray || [];
  const [animLayerTravelBookingInfo] =
    animLayerTravelBookingInfoBlokArray || [];

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        className={variantBackgroundColor.background_white}
        padding="spacing3"
        borderRadius="md"
        maxWidth="gridSpan3"
        display="flex"
        flexDirection="column"
        rowGap="spacing2"
        width="100%"
        marginX="auto"
        {...rest}
      >
        {animLayerTravelHotelInfo && (
          <AnimLayerTravelHotelInfo {...animLayerTravelHotelInfo} />
        )}

        {animLayerTravelBookingInfo && (
          <AnimLayerTravelBookingInfo
            className={variantBackgroundColor.background_sand}
            {...animLayerTravelBookingInfo}
          />
        )}

        {/* Grid with booking details */}

        <AnimLayerFakeButton data-motion={ANIMATED.secondary}>
          {ctaText}
        </AnimLayerFakeButton>
      </AnimatedElement>
    </StoryblokEditable>
  );
}
