import React, { useContext } from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { PageQueryContext } from "~context/PageQueryContext";

import { SvgIllustrationDeposit } from "../../__layers__/SvgIllustrationDeposit";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationDepositProps
  extends StoryblokBlok,
    BoxProps {
  amount?: number;
  label?: string;
}

export function AnimationIllustrationDeposit({
  amount,
  label,
  className: userClassName,
  ...rest
}: AnimationIllustrationDepositProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        maxWidth="gridSpan4"
        borderRadius="md"
        padding="spacing3"
        textAlign="center"
        className={clsx(userClassName, variantBackgroundColor.background_white)}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="spacing2"
        {...rest}
      >
        <SvgIllustrationDeposit data-motion={ANIMATED.secondary} width="100%" />
        <Box textAlign="center" data-motion={ANIMATED.secondary}>
          {label && <Box textAppearance="body_md">{label}</Box>}
          {amount && (
            <Box textAppearance="body_lg" fontWeight="semibold">
              {formatCurrencyString({
                amount,
                lang,
              })}
            </Box>
          )}
        </Box>
      </AnimatedElement>
    </StoryblokEditable>
  );
}
