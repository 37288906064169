import React from "react";

import { PhoneMockup } from "~components/PhoneMockup";

import { AnimMockUiEcommerce1 } from "~animations/mock_ui/AnimMockUiEcommerce1";

import type { AnimMockUiEcommerce1Props } from "~animations/mock_ui/AnimMockUiEcommerce1";
import type { AnimatedHeroIllustration } from "~animations/products_addons/types";

export interface AnimIndustryEcommerceProps
  extends AnimMockUiEcommerce1Props,
    AnimatedHeroIllustration {}

export function AnimIndustryEcommerce({
  animLayerEcommerceItems,
  animLayerFakeButton,
  animLayerEcommerceOrderDetails,
  title,
  ...rest
}: AnimIndustryEcommerceProps) {
  return (
    <PhoneMockup
      aspectRatio="mobile"
      display="flex"
      flexDirection="column"
      rowGap="spacing2"
      {...rest}
    >
      <AnimMockUiEcommerce1
        animLayerEcommerceItems={animLayerEcommerceItems}
        animLayerFakeButton={animLayerFakeButton}
        animLayerEcommerceOrderDetails={animLayerEcommerceOrderDetails}
        title={title}
      />
    </PhoneMockup>
  );
}
