import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { SvgIllustrationPaymentRate } from "../../__layers__/SvgIllustrationPaymentRate";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationPaymentRateProps
  extends StoryblokBlok,
    BoxProps {
  title?: string;
}

export function AnimationIllustrationPaymentRate({
  title,
  className: userClassName,
  ...rest
}: AnimationIllustrationPaymentRateProps) {
  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        display="flex"
        borderRadius="md"
        maxWidth="gridSpan3"
        alignItems="center"
        flexDirection="column"
        gap="spacing2"
        padding="spacing3"
        className={clsx(userClassName, variantBackgroundColor.background_white)}
        {...rest}
      >
        <Box
          textAppearance="body_lg"
          fontWeight="bold"
          marginBottom="spacing2"
          data-motion={ANIMATED.secondary}
        >
          {title}
        </Box>
        <SvgIllustrationPaymentRate
          width="75%"
          data-motion={ANIMATED.secondary}
        />
      </AnimatedElement>
    </StoryblokEditable>
  );
}
