import React from "react";

import { PhoneMockup } from "~components/PhoneMockup";

import { AnimMockUiAccountOverview } from "~animations/mock_ui/AnimMockUiAccountOverview";

import type { AnimMockUiAccountOverviewProps } from "~animations/mock_ui/AnimMockUiAccountOverview";
import type { AnimatedHeroIllustration } from "~animations/products_addons/types";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimIndustryFinancialServicesProps
  extends StoryblokBlok,
    AnimatedHeroIllustration,
    AnimMockUiAccountOverviewProps {}

export function AnimIndustryFinancialServices({
  ...rest
}: AnimIndustryFinancialServicesProps) {
  return (
    <PhoneMockup aspectRatio="mobile" display="flex" flexDirection="column">
      <AnimMockUiAccountOverview {...rest} />
    </PhoneMockup>
  );
}
