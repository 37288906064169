import * as React from "react";

export function StatusProgress() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <circle cx="16" cy="16" r="14.4" stroke="#F5F5F5" strokeWidth="3.2" />
      <path
        fill="#1A73E8"
        d="M0 16A16 16 0 1 0 16 0v3.2s1.814.049 3.2.406A12.8 12.8 0 1 1 5.357 23.111c-.803-1.202-1.364-2.35-1.75-3.911C3.22 17.639 3.2 16 3.2 16H0Z"
      />
      <path
        fill="#1A73E8"
        d="M22 12v8c0 1.094-.906 2-2 2h-8c-1.125 0-2-.906-2-2v-8c0-1.094.875-2 2-2h8c1.094 0 2 .906 2 2Z"
      />
    </svg>
  );
}
