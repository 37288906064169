import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Image } from "~components/Image";
import { PhoneMockup } from "~components/PhoneMockup";

import { AnimLayerTravelBookingInfo } from "~animations/__layers__/mock_ui_components/AnimLayerTravelBookingInfo";
import { AnimLayerTravelHotelInfo } from "~animations/__layers__/mock_ui_components/AnimLayerTravelHotelInfo";
import { AnimLayerUiBottomCheckout } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomCheckout";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";

import type { AnimLayerTravelBookingInfoProps } from "~animations/__layers__/mock_ui_components/AnimLayerTravelBookingInfo";
import type { AnimLayerTravelHotelInfoProps } from "~animations/__layers__/mock_ui_components/AnimLayerTravelHotelInfo";
import type { AnimLayerUiBottomCheckoutProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomCheckout";
import type { AnimatedHeroIllustration } from "~animations/products_addons/types";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface AnimIndustryTravelProps
  extends StoryblokBlok,
    AnimatedHeroIllustration {
  animLayerTravelHotelInfo: Array<
    StoryblokBlok & AnimLayerTravelHotelInfoProps
  >;
  animLayerTravelBookingInfo: Array<
    StoryblokBlok & AnimLayerTravelBookingInfoProps
  >;
  animLayerUiBottomCheckout: Array<
    StoryblokBlok & AnimLayerUiBottomCheckoutProps
  >;
  image: StoryblokFieldMedia;
  title?: string;
}

export function AnimIndustryTravel({
  animLayerTravelHotelInfo: animLayerTravelHotelInfoBlokArray,
  animLayerTravelBookingInfo: animLayerTravelBookingInfoBlokArray,
  animLayerUiBottomCheckout: animLayerUiBottomCheckoutBlokArray,

  image,
  title,
}: AnimIndustryTravelProps) {
  const [animLayerTravelHotelInfo] = animLayerTravelHotelInfoBlokArray || [];
  const [animLayerTravelBookingInfo] =
    animLayerTravelBookingInfoBlokArray || [];
  const [animLayerUiBottomCheckout] = animLayerUiBottomCheckoutBlokArray || [];

  return (
    <PhoneMockup
      aspectRatio="mobile"
      display="flex"
      gap="spacing2"
      flexDirection="column"
    >
      {title && <AnimLayerUiTopNav title={title} iconLeft="faChevronLeft" />}

      {image && (
        <Image
          marginX="spacing2"
          borderRadius="sm"
          image={image}
          border="default"
        />
      )}

      {animLayerTravelHotelInfo && (
        <AnimLayerTravelHotelInfo
          marginX="spacing2"
          {...animLayerTravelHotelInfo}
        />
      )}

      {animLayerTravelBookingInfo && (
        <AnimLayerTravelBookingInfo
          marginX="spacing2"
          className={variantBackgroundColor.background_sand}
          {...animLayerTravelBookingInfo}
        />
      )}

      {animLayerUiBottomCheckout && (
        <AnimLayerUiBottomCheckout {...animLayerUiBottomCheckout} />
      )}
    </PhoneMockup>
  );
}
