import React from "react";

import { faChartMixed } from "@fortawesome/sharp-regular-svg-icons/faChartMixed";
import { faWallet } from "@fortawesome/sharp-regular-svg-icons/faWallet";

import { Box } from "~components/Box";
import { PhoneMockup } from "~components/PhoneMockup";

import { SvgLogoCryptosell } from "~animations/__layers__/logos/SvgLogoCryptosell";
import { AnimLayerCryptoCoins } from "~animations/__layers__/mock_ui_components/AnimLayerCryptoCoins";
import { AnimLayerUiAccountInfoCard } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import { AnimLayerUiBottomNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomNav";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";

import type { AnimLayerCryptoCoinsProps } from "~animations/__layers__/mock_ui_components/AnimLayerCryptoCoins";
import type { AnimLayerUiAccountInfoCardProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiAccountInfoCard";
import type { AnimatedHeroIllustration } from "~animations/products_addons/types";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimIndustryCryptoProps
  extends StoryblokBlok,
    AnimatedHeroIllustration {
  textWithdrawalComplete?: string;
  textSuccess?: string;
  textComplete?: string;
  title: string;
  animLayerUiAccountInfoCard: Array<
    StoryblokBlok & AnimLayerUiAccountInfoCardProps
  >;
  animLayerCryptoCoins: Array<StoryblokBlok & AnimLayerCryptoCoinsProps>;
}

export function AnimIndustryCrypto({
  title,
  animLayerUiAccountInfoCard: animLayerUiAccountInfoCardBlokArray,
  animLayerCryptoCoins: animLayerCryptoCoinsBlokArray,
  ...rest // <- Important so parent can pass `data-motion` attributes
}: AnimIndustryCryptoProps) {
  const [animLayerUiAccountInfoCard] =
    animLayerUiAccountInfoCardBlokArray || [];

  const [animLayerCryptoCoins] = animLayerCryptoCoinsBlokArray || [];

  return (
    <PhoneMockup
      aspectRatio="mobile"
      display="flex"
      flexDirection="column"
      {...rest}
    >
      <Box
        marginY="spacing3"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SvgLogoCryptosell />
      </Box>

      {title && <AnimLayerUiTopNav title={title} />}

      {animLayerUiAccountInfoCard && (
        <AnimLayerUiAccountInfoCard
          marginX="spacing2"
          {...animLayerUiAccountInfoCard}
        />
      )}

      {animLayerCryptoCoins && (
        <AnimLayerCryptoCoins {...animLayerCryptoCoins} />
      )}

      <AnimLayerUiBottomNav
        icon2={faWallet}
        icon3={faChartMixed}
        marginTop="auto"
      />
    </PhoneMockup>
  );
}
