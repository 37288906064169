import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { SvgIllustrationSignupRate } from "../../__layers__/SvgIllustrationSignupRate";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationIllustrationSignupRateProps
  extends StoryblokBlok,
    BoxProps {
  title?: string;
}

export function AnimationIllustrationSignupRate({
  title,
  className: userClassName,
  ...rest
}: AnimationIllustrationSignupRateProps) {
  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        alignItems="center"
        borderRadius="md"
        className={clsx(userClassName, variantBackgroundColor.background_white)}
        display="flex"
        flexDirection="column"
        gap="spacing2"
        maxWidth="gridSpan3"
        padding="spacing3"
        textAlign="center"
        width="100%"
        {...rest}
      >
        <Box
          textAppearance="body_lg"
          fontWeight="bold"
          data-motion={ANIMATED.secondary}
        >
          {title}
        </Box>
        <SvgIllustrationSignupRate
          width="100%"
          data-motion={ANIMATED.secondary}
        />
      </AnimatedElement>
    </StoryblokEditable>
  );
}
