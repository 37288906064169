import * as React from "react";

export function StatusTick() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <rect width="32" height="32" fill="#B2ECDC" rx="16" />
      <path
        fill="#008F66"
        d="m24.35 11.482-.809.809-9 9-.773.809-.809-.809-4.5-4.5-.809-.809L9.268 14.4l.773.809 3.727 3.691 8.191-8.191.809-.809 1.582 1.582Z"
      />
    </svg>
  );
}
