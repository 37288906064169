import * as React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { AnimLayerTransactionList } from "~animations/__layers__/mock_ui_components/AnimLayerTransactionList";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import * as styles from "./styles.css";

import type { AnimationPaymentsListItemModel } from "./types";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimationPaymentsListProps extends BoxProps {
  items: Array<StoryblokBlok & AnimationPaymentsListItemModel>;
}

export function AnimationPaymentsList({
  items = [],
  className: userClassName,
  ...rest
}: AnimationPaymentsListProps) {
  return (
    <AnimatedElement
      animationLevel="primary"
      className={clsx(
        styles.container,
        userClassName,
        variantBackgroundColor.background_white
      )}
      {...rest}
    >
      <AnimLayerTransactionList
        iconBackgroundColor="dark"
        isStatusVisible
        transactions={items}
      />
    </AnimatedElement>
  );
}
